import { Fragment } from 'react';
import logo from '../images/logo.jpg';

const LIGHT_BLUE = 'rgba(99, 201, 215, 1)';
const DARK_BLUE = 'rgba(17, 23, 75, 1)';


export default function ComingSoon() {
  return (
    <Fragment>
      <div style={{
        paddingTop: '50px',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          display: 'grid',
          justifyContent: 'center',
        padding: '15px'}}
        >
          <img src={logo} alt='logo' height={'100px'}></img>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          fontFamily: 'Ostrich Sans',
          fontSize: '100px'
        }}>
          <span style={{ display: 'inline', color: `rgba(38, 50, 110, 1)` }}>TOKA</span>
          <span style={{ display: 'inline', color: `${LIGHT_BLUE}` }}>TI</span>
        </div>
        <div style={{
          fontSize: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontFamily: 'Blogger',
          paddingTop: '10px',
          paddingBottom: '13px',
          paddingLeft: '30px',
          paddingRight: '30px',
          textAlign: 'center',
          color: `${LIGHT_BLUE}`,
          backgroundColor: `${DARK_BLUE}`
        }}>
          твоят брокер на електрическа енергия
        </div>
        <div style={{
          display: 'flex',
          padding: '50px',
          justifyContent: 'center',
          fontSize: '30px',
          fontFamily: 'Blogger',
          color: `${DARK_BLUE}` 
        }}>
          ОЧАКВАЙТЕ СКОРО!
        </div>
      </div>
    </Fragment>
  )
}