import './App.css';

import ComingSoon from './components/coming-soon';

function App() {
  return (
    <ComingSoon />
  );
}

export default App;
